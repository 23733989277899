<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="content.header !== undefined && !isPageLoading">
  <!-- hero section -->
  <div
    class="container-fluid falcon-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left col-11">
          <h1
            class="mega-title--mobile falconHeading text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="ml-4">
              <h3
                class="mb-4 text-shadow"
                [ngClass]="
                  content?.header?.subheadingColour
                    ? content?.header?.subheadingColour
                    : 'white'
                "
              >
                {{ content?.header?.subHeading }}
              </h3>
              <ng-container *ngFor="let action of content?.header?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                  icon="icon-envelope"
                >
                </button-text>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- single card -->
  <div class="container campaign-hero--cards">
    <div class="d-flex justify-content-center">
      <div class="blur-card">
        <p
          class="small-heading text-center"
          [innerHTML]="content?.header?.excerpt"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></p>
      </div>
    </div>
  </div>
  <!-- /single cards -->
  <!-- hero section -->

  <!-- profile cards -->
  <div class="container" *ngIf="content?.body?.profileCards.length > 0">
    <div class="row my-3">
      <div
        class="col-md-4 py-3"
        *ngFor="let card of content?.body?.profileCards"
      >
        <div class="blur-card">
          <!-- title -->
          <div class="heading-border-left mb-0">
            <h2>{{ card?.heading }}</h2>
          </div>
          <h3>{{ card?.headline }}</h3>
          <img [src]="card?.image" *ngIf="card?.image" class="img-fluid mt-3" />
          <!-- content -->
          <div class="mt-4 pl-md-2">
            <p class="body-med" [innerHTML]="card?.content"></p>
          </div>

          <!-- download link -->
          <div
            class="d-flex align-items-center pl-md-2 product-dl"
            *ngFor="let download of card?.downloads"
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>

          <div class="row mt-3 pl-md-3">
            <div class="col-md-12 pl-md-2 btn-row">
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }} mr-2"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profile cards -->

  <!-- cards -->
  <div class="container">
    <div class="row my-3">
      <div class="col-md-6 py-3" *ngFor="let card of content?.body?.cards">
        <div class="blur-card">
          <!-- title -->
          <div class="heading-border-left mb-0">
            <h2>{{ card?.heading }}</h2>
          </div>
          <h3>{{ card?.headline }}</h3>
          <img [src]="card?.image" *ngIf="card?.image" class="img-fluid mt-3" />
          <!-- content -->
          <div class="mt-4 pl-md-2">
            <p class="body-med" [innerHTML]="card?.content"></p>
          </div>

          <!-- download link -->
          <div
            class="d-flex align-items-center pl-md-2 product-dl"
            *ngFor="let download of card?.downloads"
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>

          <div class="row mt-3 pl-md-3">
            <div class="col-md-12 pl-md-2 btn-row">
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }} mr-2"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- cards -->

  <!-- prizes -->
  <div class="container-fluid pt-5 svg-bg-l stock-br">
    <div class="container my-5">
      <div class="heading-border-left">
        <h1>{{ content?.body?.prizesHeading }}</h1>
      </div>
      <h4 [innerHTML]="content?.body?.prizesExcerpt"></h4>
      <div class="my-3">
        <ng-container *ngFor="let action of content?.body?.prizesActions">
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
            icon="icon-envelope"
          >
          </button-text>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </ng-container>
      </div>

      <div class="row my-3">
        <div class="col-12 col-md-4">
          <div class="card-round white-bg p-4 slight-raise h-100 text-center">
            <img src="/assets/images/trophy.png" class="img-fluid w-25" />
            <h3 class="large-heading cyan mb-3">1st prize</h3>
            <h3>R {{ prizes[0] }}</h3>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card-round white-bg p-4 slight-raise h-100 text-center">
            <img src="/assets/images/trophy.png" class="img-fluid w-25" />
            <h3 class="large-heading cyan mb-3">2nd prize</h3>
            <h3>R {{ prizes[1] }}</h3>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card-round white-bg p-4 slight-raise h-100 text-center">
            <img src="/assets/images/trophy.png" class="img-fluid w-25" />
            <h3 class="large-heading cyan mb-3">3rd prize</h3>
            <h3>R {{ prizes[2] }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- prizes -->

  <!-- special prizes -->
  <div class="container">
    <div class="row my-3">
      <div
        class="col-12 py-3"
        *ngFor="let card of content?.body?.prizesSpecial"
      >
        <div class="blur-card">
          <!-- title -->
          <div class="heading-border-left mb-0">
            <h2>{{ card?.heading }}</h2>
          </div>
          <h3>{{ card?.headline }}</h3>

          <!-- content -->
          <div class="mt-4 pl-md-2">
            <p class="body-med" [innerHTML]="card?.content"></p>
          </div>

          <!-- download link -->
          <div
            class="d-flex align-items-center pl-md-2 product-dl"
            *ngFor="let download of card?.downloads"
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>

          <div class="row mt-3 pl-md-3">
            <div class="col-md-12 pl-md-2 btn-row">
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }} mr-2"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- special prizes -->

  <!-- panel -->
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="blur-card">
        <div class="heading-border-left d-inline-flex justify-content-start">
          <h2>
            {{ content?.body?.panelHeading }}
          </h2>
        </div>
        <div class="row my-3">
          <div
            class="col-6 col-md-2"
            *ngFor="let profile of content?.body?.panelProfiles"
          >
            <div class="text-center">
              <img [src]="profile?.image" class="img-fluid rounded" />
            </div>
            <div class="p-2">
              <strong>{{ profile?.name }}</strong
              ><br />
              <small>{{ profile?.description }}</small>
            </div>
          </div>
        </div>
        <p [innerHTML]="content?.body?.panelExcerpt"></p>
        <ng-container *ngFor="let action of content?.body?.panelActions">
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
            icon="icon-envelope"
          >
          </button-text>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- panel -->

  <!-- videos -->
  <div class="container my-3" *ngIf="content?.body?.videos.length > 0">
    <div class="row">
      <div class="col-md-12" *ngFor="let video of content?.body?.videos">
        <h4 class="small-heading cyan mt-4 mt-md-0">{{ video?.heading }}</h4>
        <!-- video -->
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            class="embed-responsive-item"
            [src]="video?.url | safe"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <!-- /video -->
      </div>
    </div>
  </div>

  <!-- criteria -->
  <div class="container">
    <div class="row my-3">
      <div
        class="col-12 py-3"
        *ngFor="let card of content?.body?.criteriaCards"
      >
        <div class="blur-card">
          <!-- title -->
          <div class="heading-border-left mb-0">
            <h2>{{ card?.heading }}</h2>
          </div>
          <h3>{{ card?.headline }}</h3>

          <!-- content -->
          <div class="mt-4 pl-md-2">
            <p class="body-med" [innerHTML]="card?.content"></p>
          </div>

          <!-- download link -->
          <div
            class="d-flex align-items-center pl-md-2 product-dl"
            *ngFor="let download of card?.downloads"
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>

          <div class="row mt-3 pl-md-3">
            <div class="col-md-12 pl-md-2 btn-row">
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }} mr-2"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- criteria -->

</div>
