<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid diu-economic-calender-section"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title mb-0"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
        <p
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<div
  class="container-fluid py-3 my-3"
  *ngFor="let event of events"
>
  <div class="container-xl">
    <div class="row d-flex">
      <div class="col-md-12">
        <div class="card-round significant-raise white-bg pt-5 pb-3 px-5">
          <h4>{{ getDay(event?.properties?.calendarEvents?.items[0]?.content?.properties?.day) }}</h4>
          <p class="body-small cyan">{{ convertDate(event?.properties?.calendarEvents?.items[0]?.content?.properties?.day) }}</p>
          <div class="table-responsive" [innerHTML]="event?.properties?.calendarEvents?.items[0]?.content?.properties?.dayContent?.markup"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Recommended news  cards section -->
<div class="container-fluid py-5 my-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[0]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let article of content?.body?.sections[0]?.articles"
      >
        <img alt="Card image cap" class="card-img-top" [src]="article.image" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of article.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ article.headline }}</h5>
          <p class="body-small" [innerHTML]="article.excerpt"></p>
          <a [routerLink]="article.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Recommended news  cards section -->
