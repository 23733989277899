<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- article overview & featured img -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mb-5 mt-4 svg-bg-r featured-img-bg"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-12">
        <!-- breacrumbs -->
        <div class="mb-4">
          <ul class="breadcrumb pl-0">
            <li>
              <a [routerLink]="['/news-and-publications']"
                >News & publications</a
              >
            </li>
            <icon-font name="icon-angle-right"></icon-font>
            <li class="breadcrumb-active">{{ content?.properties?.title }}</li>
          </ul>
        </div>
        <!-- /breacrumbs -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-5 py-0 py-md-4 order-1 order-md-0">
        <!-- article info -->
        <div>
          <!-- date -->
          <div class="mb-3">
            <h2 class="xlarge-heading mb-0">
              {{ content?.properties?.publishDate | amDateFormat : "DD" }}
            </h2>
            <p class="body-small body-bold">
              {{ content?.properties?.publishDate | amDateFormat : "MMMM YYYY" }}
            </p>
          </div>
          
          <!-- category tags -->
          <div class="card-tags my-3">
            <span
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle('Daily Investment Update'); $event.preventDefault()
              "
              >Daily Investment Update</span
            >
          </div>
          <!-- <div class="card-tags my-3">
            <span
              *ngFor="let category of content?.header?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div> -->
          <!-- title -->
          <div>
            <h1 class="xlarge-heading">{{ content?.properties?.title }}</h1>
          </div>
          <!-- author -->
          <div
            *ngFor="let author of content?.properties?.author"
            class="d-flex flex-row justify-content-start align-items-center my-4"
          >
            <img
              (click)="goToAdvisorProfile(author.id)"
              [src]="apiBaseUrl + author?.properties?.authorImage[0].url"
              alt="Avatar"
              class="image-medium-avatar pointer mr-3"
              [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
            />
            <div class="d-flex flex-column">
              <p
                (click)="goToAdvisorProfile(author.id)"
                class="body-med body-bold pointer cyan mb-0"
              >
                {{ author.name }}
              </p>
              <p class="body-small mb-0">
                {{ author.properties.authorDivision }}
              </p>
            </div>
          </div>
          <!-- article excerpt -->
          <div>
            <p [innerHTML]="content?.header?.excerpt"></p>
          </div>
        </div>
        <!-- /article info -->
        <!--social icons-->

        <div class="row d-flex text-left">
          <button class="button simple-icon" (click)="shareToMail()">
            <icon-font
              name="icon-envelope"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToLinkedIn()">
            <icon-font
              name="icon-linkedin"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToTwitter()">
            <icon-font
              name="icon-twitter"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToFb()">
            <icon-font
              name="icon-facebook"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToWhatsApp()">
            <icon-font
              name="icon-whatsapp"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
        </div>

        <!--social icons-->
      </div>

      <div
        *ngIf="!isPageLoading"
        class="col-md-6 col-lg-6 offset-lg-1 order-0 order-md-1"
      >
        <!-- featured img -->
        <img
          [src]="apiBaseUrl + content?.properties?.publicationImage?.url"
          class="significant-raise w-100 img-fluid"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
        />
        <!-- /featured img -->
      </div>
    </div>
  </div>
</div>

<!-- Market commentary section -->
<div *ngIf="!isPageLoading" class="container-fluid my-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-8">
        <h3 class="medium-heading">
          {{ content?.body?.sections[0]?.heading }}
        </h3>
        <p class="pt-3" [innerHTML]="content?.properties?.blocks?.items[0]?.content?.properties?.text?.markup"></p>
      </div>
      <div class="col-md-4">
        <div class="card-flat slight-raise">
          <app-diu-article-navigation
            [content]="content"
          ></app-diu-article-navigation>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ Market commentary section -->

<!-- share-price-ticker -->

<div *ngIf="!isPageLoading" class="container-xl py-2">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>

<!-- market-indicators -->
<div
  *ngIf="!isPageLoading"
  class="container-xl mt-5 p-0 mb-5"
  id="market-indicator"
>
  <div class="col-md-12">
    <app-market-indicator></app-market-indicator>
  </div>
</div>

<!-- Local Funds -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5 mt-5"
  id="local-diu-funds"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-8">
        <h3 class="medium-heading">
          {{ content?.body?.sections[3]?.heading }}
        </h3>
      </div>
    </div>
  </div>
  <div class="container-xl px-0 mt-2">
    <app-psg-funds-local [showOnlyPriceAndPerformance]="true">
    </app-psg-funds-local>
  </div>
</div>
<!--/ Local Funds -->

<!-- Global Funds -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="global-diu-funds"
>
  <div class="container-xl px-0 h-100">
    <app-psg-funds-global [showOnlyPriceAndPerformance]="true">
    </app-psg-funds-global>
  </div>
</div>
<!--/ Global Funds -->

<!-- Invest in other currencies -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="other-diu-funds"
>
  <div class="container-xl px-0 h-100">
    <app-psg-funds-other-currencies [showOnlyPriceAndPerformance]="true">
    </app-psg-funds-other-currencies>
  </div>
</div>
<!--/ Invest in other currencies -->

<!-- Hosue view Portfolios -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5 mt-5"
  id="house-diu-funds"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-8">
        <h3 class="medium-heading">
          {{ content?.body?.sections[4]?.heading }}
        </h3>
      </div>
    </div>
  </div>
  <div class="container-xl px-0 mt-2">
    <app-mm-funds-house-view [showOnlyPriceAndPerformance]="true">
    </app-mm-funds-house-view>
  </div>
</div>
<!--/ Hosue view Portfolios -->

<!-- spacer -->
<div class="container-fluid pt-5 pb-5"></div>

<!-- recent investment ideas -->
<!-- <div> </div> -->
<div
  class="container-fluid my-5 pb-5 svg-bg-l diu-bg"
  *ngIf="recentInvestmentIdeas.length > 0"
  id="recent-investment-ideas"
>
  <div class="container-xl">

    <div class="row">
      <div class="col-md-11" style="margin-top: -2.75rem">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            Recent investment ideas
          </h2>
        </div>
      </div>
    </div>

    <div class="row mt-2 mt-md-5" *ngFor="let idea of recentInvestmentIdeas">
      <div class="col-md-6 mb-4">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            [id]="idea?.id"
            class="embed-responsive-item"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            [src]="idea?.properties?.videoURL[0]?.url | safe"
          >
          </iframe>
        </div>
      </div>
      <div class="col-md-5 offset-md-1">
        <h3 class="medium-heading cyan">
          {{ idea?.properties?.title }}
        </h3>
        <div [innerHTML]="idea?.properties?.teaserContent?.markup"></div>
      </div>
    </div>
  </div>
</div>

<!-- cards -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5"
  [hidden]="contentOther?.body?.sections[0]?.sections[1]?.cards === null"
  id="product-metrics-and-economic-calendar"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-5 offset-md-1 mb-4">
        <div class="card-flat">
          <h3 class="medium-heading">
            {{ contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.heading }}
          </h3>
          <p
            [innerHTML]="
              contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.content
            "
          ></p>
          <div
            *ngIf="
              contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.actions
                .length > 0
            "
          >
            <button-text
              [attr.buttonStyle]="
                contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.actions[0]
                  .isPrimary
                  ? 'solid'
                  : 'outline'
              "
              [label]="
                contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.actions[0]
                  ?.label
              "
              [routerLink]="[
                contentOther?.body?.sections[0]?.sections[1]?.cards[0]?.actions[0]
                  ?.url
              ]"
            >
            </button-text>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Corporate Actions -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5 mt-5"
  [hidden]="contentOther?.body?.sections[5] === null"
  id="corporate-actions-calendar"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="slight-raise table-card">
          <h3 class="medium-heading">
            {{ corporateActions?.name }}
          </h3>
          <div class="pt-3">
            <div class="table-responsive">
              <!---------------------- -->
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style="width: 25%; min-width: 140px">
                      Date
                    </th>
                    <th scope="col" style="width: 25%; min-width: 140px">
                      Company
                    </th>
                    <th scope="col" style="width: 25%; min-width: 140px">
                      Share code
                    </th>
                    <th scope="col" style="width: 25%; min-width: 140px">
                      Expectation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let corporateAction of corporateActions?.properties?.actions?.items"
                  >
                    <td>
                      {{
                        corporateAction?.content?.properties?.date | amDateFormat : "DD MMMM YYYY"
                      }}
                    </td>
                    <td>{{ corporateAction?.content?.properties?.company }}</td>
                    <td>{{ corporateAction?.content?.properties?.shareCode }}</td>
                    <td [innerHTML]="corporateAction?.content?.properties?.expectation?.markup"></td>
                  </tr>
                </tbody>
              </table>
              <!-------------------------->
              <div>
                <p
                  class="body-small mb-0"
                  [innerHTML]="contentOther?.body?.sections[5]?.disclaimer"
                ></p>
                <div class="container mb-2">
                  <div class="row">
                    <ng-container
                      *ngFor="let action of contentOther?.body?.sections[5]?.actions"
                    >
                      <button-text
                        class="mr-2 mb-2"
                        buttonStyle="{{
                          action?.buttonColor !== null
                            ? action?.buttonColor
                            : action.isPrimary
                            ? 'solid'
                            : 'outline'
                        }}"
                        [label]="action?.label"
                        (click)="DynamicButtonClick(action)"
                      >
                      </button-text>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ Corporate Actions -->

<!-- Fund performance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l details--fundP h-100"
  [hidden]="contentOther?.body?.sections[6] === null"
  id="fund-performance"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-7 ml-md-4">
        <div class="large-heading">
          <h2>{{ contentOther?.body?.sections[6]?.heading }}</h2>
        </div>
        <div>
          <p
            class="p-length-lg"
            [innerHTML]="contentOther?.body?.sections[6]?.sections[0]?.content"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ Fund performance -->

<!-- Recommended news -->
<div
  class="container-fluid my-5 py-5 slate-bg-10"
  *ngIf="
    contentOther?.body?.sections[2] !== null ||
    contentOther?.body?.sections[2] !== undefined
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-items-center justify-content-left h-100">
      <h2 class="mb-4">{{ contentOther?.body?.sections[2]?.heading }}</h2>

      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let news of contentOther?.body?.sections[2]?.articles"
        >
          <img
            class="card-img-top"
            [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
            [src]="news?.image"
            alt="Card image cap"
          />
          <div class="card-body">
            <div class="card-tags pb-2">
              <span
                *ngFor="let category of news?.categories"
                role="button"
                class="basic-badge"
                data-toggle="modal"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ news?.headline }}</h5>
            <p class="body-small" [innerHTML]="news?.excerpt"></p>
            <a [routerLink]="news?.uri" class="textlink">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Recommended news -->
