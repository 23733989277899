export const environment = {
  production: true,
  umbracoApiUrl: "https://psgpfwcmsapiprod.azurewebsites.net",
  findAdvisorApiUrl: "https://mybase.psg.co.za/index.php?c=rest2/pfw/v1/pfw/",
  sendLeadApiUrl: "https://mypractice.psg.co.za/api",
  dailyInvestmentUrl: "https://psg-diu-marketindicators.azurewebsites.net",
  fundPricesAndPerformancesUrl: "https://psg-diu-fundspricesandperformances.azurewebsites.net",
  fundPriceAndPerformanceHousePortfilioUrl: "https://psg-diu-equityportfoliomatrix.azurewebsites.net/",
  GoogleApiKey: "AIzaSyAP8POOsQC86jMSc5D0KXXPlP3jl9yE4Zs",
  globalSiteUrl: "https://psgkglobal.com/",
  leadServiceAccount: {
    id: "psgwebsite",
    secret: "G1jdKgfEUxrkOiUP5DiWUsqRWMWKu4s8IG6HwmNNHq90WVXN7WvJNGpkKKm9KUOB",
    username: "system.psgwebsite",
    password: "h4LwFEE!M8mWv#WM",
  },
  defaultLandingPageUrl: "https://alpha.psg.co.za/secure/accesscontrol-ui/",
  advisersUrl: "https://advisers.psg.co.za",
  enhancedInterestFof: {
    url: 'https://apim-psg-gsdm.azure-api.net/psgcashaccountrates/EnhancedInterestFoF',
    value: 'c7cb65b2cfc84697aa5117f8124c86c5'
  },
  loginURL: {
    myPSG: 'https://my.psg.co.za',
    wealthFunds: 'https://alpha.psg.co.za/secure/platform/auth/oauthsso',
    PSGOnline: 'https://www.psg-online.co.za/CDNW/Index.aspx',
    allPlatformAccess: 'https://psg-auth-middleware.azurewebsites.net/api/Authenticate?code=3hnVPDs3gCeGUzD8xq0DCOBJdku6SKLdhQTGp3EANx0wAzFufIk_6A%3D%3D'
  },
  DIU: {
    apiBaseUrl: 'https://cms.psg.co.za',
    url: 'umbraco/delivery/api/v2/content?fetch=children:/psg-financial-services/research-hub'
  }
};
