import { Injectable } from "@angular/core";
import { FundPriceAndPerformanceHousePortfilioReqeust, FundPriceAndPerformanceHousePortfilioResponse, FundsPricingPerformanceRequest, FundsPricingPerformanceResponse, FundTablesOverviewModel, MarketIndicatorResponse, MarketIndicatorsRequest } from "./daily-ivestment.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoggerService } from "../base/logger.service";
import { BaseService } from "../base/base.service";
import { environment } from "src/environments/environment";


@Injectable()
export class DailyInvestmentService extends BaseService {

  private baseUrl: string = environment.dailyInvestmentUrl;
  private fundPricesAndPerformancesBaseUrl: string = environment.fundPricesAndPerformancesUrl;
  private fundPriceAndPerformanceHousePortfilioBaseUrl: string = environment.fundPriceAndPerformanceHousePortfilioUrl;

  getLatestArticle() {
    return this.http.get<any>(`${environment.DIU.apiBaseUrl}/${environment.DIU.url}/investment-updates/daily-investment-updates/&sort=publishDate:desc&skip=0&take=1&expand=properties[$all]&fields=properties[$all]`);
  }

  getRecentInvestmentIdeas() {
    return this.http.get<any>(`${environment.DIU.apiBaseUrl}/${environment.DIU.url}/investment-ideas-videos/&sort=publishDate:desc&skip=0&take=3&expand=properties[$all]&fields=properties[$all]`);
  }

  getCorporateActions() {
    return this.http.get<any>(`${environment.DIU.apiBaseUrl}/${environment.DIU.url}/corporate-actions/&sort=publishDate:desc&skip=0&take=1&fields=properties[$all]`);
  }

  public getMarketIndicators(request: MarketIndicatorsRequest): Observable<Array<MarketIndicatorResponse>> {
    let marketIndicatorAddress = `${this.baseUrl}/market-indicators`;
    return this.Post(marketIndicatorAddress, request);
  }

  public getFundPricesAndPerformances(request: FundsPricingPerformanceRequest): Observable<Array<FundsPricingPerformanceResponse>> {
    let fundPricingAndPerformancesAddress = `${this.fundPricesAndPerformancesBaseUrl}/fund-prices-performances`;
    return this.Post(fundPricingAndPerformancesAddress, request);
  }

  public getFundsOverviewData(request: FundsPricingPerformanceRequest): Observable<FundTablesOverviewModel> {
    let fundPricingAndPerformancesAddress = `${this.fundPricesAndPerformancesBaseUrl}/fund-prices-performances`;
    return this.Post(fundPricingAndPerformancesAddress, request).pipe(map((result) => {
      var fundTableOverviewResult = new FundTablesOverviewModel(result);

      return fundTableOverviewResult;
    }));
  }

  public getFundPriceAndPerformanceHousePortfilio(request: FundPriceAndPerformanceHousePortfilioReqeust): Observable<Array<FundPriceAndPerformanceHousePortfilioResponse>> {
    let fundPriceAndPerformanceHousePortfilioAddress = `${this.fundPriceAndPerformanceHousePortfilioBaseUrl}equity-portfolio-matrix`;
    return this.Get(fundPriceAndPerformanceHousePortfilioAddress, request);
  }



}
